import { isAfter, isBefore, isToday } from 'date-fns';

class Category {
  constructor(data) {
    this.position = data.position;
    this.name = data.name;
    this.deals = data.linkText;
    this.image = data.image ? data.image.url : '';
    this.alt = (data.image && data.image.alt) || data.name;
    this.link = data.link;
    this.startDate = data.visibilityStartDate;
    this.endDate = data.visibilityEndDate;
  }

  isActive() {
    return (
      isAfter(new Date(), new Date(this.startDate)) &&
      (isBefore(new Date(), new Date(this.endDate)) ||
        isToday(new Date(this.endDate)))
    );
  }
}

export default {
  data: () => ({
    categoryCards: [],
    allHighlightCategories: []
  }),

  watch: {
    allHighlightCategories() {
      if (this.allHighlightCategories.length < 1) return;
      this.setCategoryCards();
      this.showCard();
    }
  },
  methods: {
    setCategoryCards() {
      this.categoryCards = this.allHighlightCategories
        .map((category) => {
          const temp = {
            ...category,
            image:
              category.images && category.images.length > 0
                ? category.images[0].image
                : category.image,
            visibilityStartDate: category.visibilityStartDate,
            visibilityEndDate: category.visibilityEndDate
          };
          return new Category(temp);
        })
        .filter((category) => category.isActive())
        .sort((b, a) => {
          return b.priority - a.priority;
        });
    }
  }
};
